<template>
  <v-row id="studio-dashbord">
    <v-col cols="12">
      <month-selector></month-selector>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-row class="py-0">
        <v-col
          class="summary-text py-0"
          cols="12"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-row>
            <v-col cols="12" class="px-1 px-md-3 py-0 pb-4">
              <v-card raised elevation="6" class="my-1" id="appointments">
                <v-card-title
                  class="py-2"
                  style="
                    cursor: pointer;
                    text-decoration: underline;
                    display: flex;
                    justify-content: center;
                  "
                  @click="$router.push({ name: 'appointmentList' })"
                >
                  {{ $tc("appointment", 2) }}
                </v-card-title>
                <v-card-text
                  class="appointment-text py-2"
                  style="background-color: #1e1e1e"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="3"
                      class="card_min d-inline-flex flex-column"
                    >
                      <div class="py-0 align-start">
                        <h3>{{ $t("expenses.types.total") }}</h3>
                      </div>
                      <div class="my-auto">
                        <v-row class="align-center">
                          <v-col class="py-0">
                            <p class="" style="font-size: 35px; line-height: 1">
                              {{ numAppointments }}
                            </p>
                            <span>
                              {{ $t("expenses.types.total") }}
                            </span>
                          </v-col>
                          <v-col class="py-0 borders">
                            <p
                              class="primary--text"
                              style="font-size: 35px; line-height: 1"
                            >
                              {{ check_out }}
                            </p>
                            <span class="primary--text">Check out</span>
                          </v-col>
                        </v-row>
                      </div>
                      <div>
                        <v-row justify="center" class="align-end">
                          <v-btn
                            v-if="!$vuetify.breakpoint.smAndDown"
                            outlined
                            style="height: 20px"
                            color="primary"
                            @click="
                              $router.push({
                                name: 'appointmentList',
                                params: {
                                  request: true,
                                  from: filters.from,
                                  to: filters.to,
                                },
                              })
                            "
                          >
                            {{ $t("showDetails") }}
                          </v-btn>
                        </v-row>
                      </div>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                      class="d-inline-flex flex-column borders-double"
                    >
                      <div class="py-0 align-start" md="12">
                        <h3>{{ $t("pending") }}</h3>
                      </div>
                      <div class="my-auto">
                        <v-row class="align-center" justify="center">
                          <v-simple-table class="pending_table">
                            <template>
                              <tr>
                                <th width="50">Fecha</th>
                                <th width="50" v-if="pendingSetting.tattooer">
                                  Artista
                                </th>
                                <th
                                  width="50"
                                  v-if="
                                    $store.getters['auth/getSetting'](
                                      'boxes_obligatoris'
                                    ) == '1' && pendingSetting.box
                                  "
                                >
                                  Box
                                </th>
                                <th
                                  width="50"
                                  v-if="pendingSetting.presupuesto"
                                >
                                  Presu
                                </th>
                                <th
                                  width="50"
                                  v-if="pendingSetting.token_payment"
                                >
                                  Paga señal
                                </th>
                                <th width="50" v-if="pendingSetting.consent">
                                  F. Consent
                                </th>
                                <th width="50">Pagado</th>
                              </tr>
                              <tr v-for="a in pendingAppointments" :key="a.i">
                                <td>
                                  {{ a.customer.user.first_name || "-" }}
                                  {{ a.customer.user.last_name }}
                                </td>
                                <td v-if="pendingSetting.date">
                                  <v-icon x-small v-if="!a.state.info.date">
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                  <v-icon x-small v-else
                                    >mdi-checkbox-blank-circle-outline</v-icon
                                  >
                                </td>
                                <td v-if="pendingSetting.tattooer">
                                  <v-icon x-small v-if="!a.state.info.tattooer"
                                    >mdi-checkbox-blank-circle</v-icon
                                  >
                                  <v-icon x-small v-else
                                    >mdi-checkbox-blank-circle-outline</v-icon
                                  >
                                </td>
                                <td
                                  v-if="
                                    $store.getters['auth/getSetting'](
                                      'boxes_obligatoris'
                                    ) == '1' && pendingSetting.box
                                  "
                                >
                                  <v-icon x-small v-if="!a.state.info.box"
                                    >mdi-checkbox-blank-circle</v-icon
                                  >
                                  <v-icon x-small v-else
                                    >mdi-checkbox-blank-circle-outline</v-icon
                                  >
                                </td>
                                <td v-if="pendingSetting.presupuesto">
                                  <v-icon x-small v-if="!a.state.info.presupost"
                                    >mdi-checkbox-blank-circle</v-icon
                                  >
                                  <v-icon x-small v-else
                                    >mdi-checkbox-blank-circle-outline</v-icon
                                  >
                                </td>

                                <td v-if="pendingSetting.token_payment">
                                  <v-icon
                                    x-small
                                    v-if="!a.state.info.token_payment"
                                    >mdi-checkbox-blank-circle</v-icon
                                  >
                                  <v-icon x-small v-else
                                    >mdi-checkbox-blank-circle-outline</v-icon
                                  >
                                </td>

                                <td v-if="pendingSetting.consent">
                                  <v-icon x-small v-if="!a.state.info.consent"
                                    >mdi-checkbox-blank-circle</v-icon
                                  >
                                  <v-icon x-small v-else>
                                    mdi-checkbox-blank-circle-outline
                                  </v-icon>
                                </td>
                                <td>
                                  <v-icon x-small v-if="!a.state.info.payed">
                                    mdi-checkbox-blank-circle
                                  </v-icon>
                                  <v-icon x-small v-else
                                    >mdi-checkbox-blank-circle-outline</v-icon
                                  >
                                </td>
                              </tr>
                              <tr v-if="pendingAppointments.length == 0">
                                <td colspan="5">
                                  <h3 class="text-center">
                                    No hay citas pendientes
                                  </h3>
                                </td>
                              </tr>
                            </template>
                          </v-simple-table>
                        </v-row>
                      </div>
                      <div>
                        <v-row justify="center" class="align-end">
                          <v-btn
                            v-if="pendingAppointmentsTotal > 0"
                            outlined
                            style="height: 20px"
                            class="my-2"
                            color="primary"
                            @click="
                              $router.push({
                                name: 'appointmentList',
                                params: {
                                  pending: true,
                                  from: filters.from,
                                  to: filters.to,
                                },
                              })
                            "
                          >
                            {{ pendingAppointmentsTotal }}
                            {{ $t("dashboard.pending_appointments") }}
                          </v-btn>
                        </v-row>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3" class="d-inline-flex flex-column">
                      <div class="py-0 align-start" cols="12" md="">
                        <h3>Solicitudes</h3>
                      </div>

                      <div class="my-auto">
                        <v-row class="align-center">
                          <v-col class="py-0">
                            <p
                              class="primary--text"
                              style="font-size: 50px; line-height: 1"
                            >
                              +{{ sol }}
                            </p>
                            <span>
                              {{ $t("expenses.types.total") }}
                            </span>
                          </v-col>
                        </v-row>
                      </div>
                      <div>
                        <v-row justify="center" class="align-end">
                          <v-btn
                            v-if="!$vuetify.breakpoint.smAndDown"
                            outlined
                            style="height: 20px"
                            color="primary"
                            @click="
                              $router.push({
                                name: 'appointmentList',
                                params: {
                                  request: true,
                                  from: filters.from,
                                  to: filters.to,
                                },
                              })
                            "
                          >
                            {{ $t("showDetails") }}
                          </v-btn>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" class="px-1 px-md-3 py-0 pb-4">
              <v-card raised elevation="6" class="my-1">
                <v-card-title
                  class="py-2"
                  style="
                    cursor: pointer;
                    text-decoration: underline;
                    display: flex;
                    justify-content: center;
                  "
                  @click="$router.push({ name: 'economyView' })"
                >
                  {{ $tc("economy", 2) }}
                </v-card-title>

                <v-card-text style="background-color: #1e1e1e" class="py-2">
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-col class="py-0" md="12">
                        <h3>{{ $t("ingresos") }}</h3>
                      </v-col>
                      <v-row class="mt-6">
                        <v-col class="py-0">
                          <p style="font-size: 20px">
                            {{ $n(income.tattooer, "currency_no_icon") }}€
                          </p>
                          <span>
                            {{ $t("artist") }}
                          </span>
                        </v-col>
                        <v-col
                          class="py-0"
                          :class="$vuetify.breakpoint.mdAndUp ? 'borders' : ''"
                        >
                          <p style="font-size: 20px">
                            {{ $n(income.studio, "currency_no_icon") }}€
                          </p>
                          <span>{{ $tc("studio") }}</span>
                        </v-col>
                      </v-row>
                      <v-row justify="center" class="mt-4">
                        <v-col class="py-0">
                          <p class="primary--text" style="font-size: 20px">
                            {{ $n(income.tattooer, "currency_no_icon") }}€
                          </p>
                          <span>
                            {{ $t("total") }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      class="borders d-inline-flex flex-column"
                      cols="12"
                      md="4"
                    >
                      <div class="py-0 align-start" md="12">
                        <h3>{{ $t("dashboard.debts") }}</h3>
                      </div>
                      <div class="my-auto">
                        <v-row class="align-center">
                          <v-col class="py-0">
                            <p class="red--text" style="font-size: 20px">
                              {{ $n(pending.tattooer, "currency_no_icon") }}€
                            </p>
                            <span>
                              {{ $t("artist") }}
                            </span>
                          </v-col>
                          <v-col class="py-0">
                            <p class="green--text" style="font-size: 20px">
                              {{ $n(pending.customer, "currency_no_icon") }}€
                            </p>
                            <span class="primary--text">
                              {{ $tc("customer") }}</span
                            >
                          </v-col>
                        </v-row>
                      </div>
                      <div>
                        <v-row justify="center" class="align-end">
                          <v-btn
                            v-if="!$vuetify.breakpoint.smAndDown"
                            outlined
                            style="height: 20px"
                            color="primary"
                            @click="
                              $router.push({
                                name: 'economyView',
                                params: { type: 'commissions' },
                              })
                            "
                          >
                            {{ $t("showDetails") }}
                          </v-btn>
                        </v-row>
                      </div>
                    </v-col>
                    <v-col
                      class="borders d-inline-flex flex-column"
                      cols="12"
                      md="3"
                      :order="$vuetify.breakpoint.smAndDown ? 1 : ''"
                    >
                      <div class="py-0 align-start" md="12">
                        <h3>Pagas y señales pendientes</h3>
                      </div>
                      <div class="my-auto">
                        <v-row class="align-center">
                          <v-col class="py-0">
                            <p style="font-size: 50px">+{{ pending_payed }}</p>
                          </v-col>
                        </v-row>
                      </div>
                      <div>
                        <v-row justify="center" class="align-end">
                          <v-btn
                            v-if="!$vuetify.breakpoint.smAndDown"
                            outlined
                            style="height: 20px"
                            color="primary"
                            @click="
                              $router.push({
                                name: 'appointmentList',
                                params: {
                                  not_payed: true,
                                  total: true,
                                  from: filters.from,
                                  to: filters.to,
                                },
                              })
                            "
                          >
                            {{ $t("showDetails") }}
                          </v-btn>
                        </v-row>
                      </div>
                    </v-col>
                    <v-col
                      class="borders d-inline-flex flex-column"
                      cols="12"
                      md="2"
                    >
                      <div class="py-0 align-start" md="12">
                        <h3>Cash acumulado</h3>
                      </div>
                      <div class="my-auto">
                        <v-row class="align-center">
                          <v-col class="py-0">
                            <p style="font-size: 24px">
                              {{ $n(totalCash, "currency_no_icon") }}€
                            </p>
                          </v-col>
                        </v-row>
                      </div>
                      <div>
                        <v-row justify="center" class="align-end">
                          <v-btn
                            v-if="!$vuetify.breakpoint.smAndDown"
                            outlined
                            style="height: 20px; width: 120px"
                            color="primary"
                            @click="$router.push({ name: 'economyView' })"
                          >
                            {{ $t("showDetails") }}
                          </v-btn>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="!$vuetify.breakpoint.smAndDown">
            <v-col cols="12" md="12" class="py-0 pb-4">
              <v-card elevation="6" class="ma-1">
                <v-card-title
                  class="py-2"
                  style="
                    cursor: pointer;
                    text-decoration: underline;
                    display: flex;
                    justify-content: center;
                  "
                  @click="$router.push({ name: 'studioList' })"
                >
                  {{ $tc("studio", 2) }}
                  <span style="font-size: 12px">
                    (+{{ tattooers.length }} studios disponibles)
                  </span>
                </v-card-title>
                <v-card-text style="background-color: #1e1e1e" class="px-1">
                  <v-simple-table class="hours-table">
                    <template>
                      <tr>
                        <th></th>
                        <th v-for="(h, i) in hours(8, 22)" :key="i">
                          {{ +h.split(":")[0] }}h
                        </th>
                      </tr>
                      <tr v-for="t in tattooers" :key="t.id">
                        <td>
                          <span> {{ t.studio.studio_name }}. </span>
                        </td>
                        <td v-for="(h, i) in hours(8, 22)" :key="i">
                          <v-icon x-small small v-if="checkAppointments(t, h)">
                            <!--esta ocupado-->
                            mdi-checkbox-blank-circle
                          </v-icon>
                          <v-icon x-small small v-else>
                            <!--Esta libre dentro del horario-->
                            mdi-checkbox-blank-circle-outline
                          </v-icon>
                        </td>
                      </tr>
                      <tr>
                        <td
                          :colspan="hours(8, 22).length + 1"
                          v-if="tattooers.length == 0"
                        >
                          <h3 class="text-center">No hay artistas activos</h3>
                        </td>
                      </tr>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="py-0" v-if="$vuetify.breakpoint.mdAndUp">
              <v-row>
                <v-col cols="4">
                  <v-card raised elevation="6" class="my-1" id="notifications">
                    <v-card-title
                      class="py-2"
                      @click="$router.push({ name: 'notifications' })"
                      style="
                        cursor: pointer;
                        text-decoration: underline;
                        display: flex;
                        justify-content: center;
                      "
                    >
                      {{ $tc("notification", 2) }}
                    </v-card-title>
                    <notifications-list
                      class="mt-2"
                      v-if="$store.getters['auth/notificationdCount'] > 0"
                    />
                    <v-card-text v-else style="background-color: #1e1e1e">
                      <v-row class="py-6">
                        <v-col>
                          <v-row>
                            <v-col class="white--text"
                              >No hay notificationes</v-col
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card raised elevation="6" class="my-1" id="alarms">
                    <v-card-title
                      class="py-2"
                      @click="
                        $router.push({
                          name: 'calendar',
                          params: { new: true },
                        })
                      "
                      style="
                        cursor: pointer;
                        text-decoration: underline;
                        display: flex;
                        justify-content: center;
                      "
                    >
                      {{ $tc("alarm", 2) }}
                    </v-card-title>
                    <v-card-text style="background-color: #1e1e1e">
                      <v-row class="py-6">
                        <v-col cols="12">
                          <v-row
                            v-for="r in reminders"
                            :key="r.id"
                            class="pointer"
                            @click="
                              $router.push({
                                name: 'calendar',
                                params: { event: r },
                              })
                            "
                          >
                            <v-col
                              align="left"
                              cols="6"
                              class="py-0 my-1 no-wrap"
                            >
                              {{ r.name }}
                            </v-col>
                            <v-col
                              cols="3"
                              class="py-0 my-1 borders-double no-wrap"
                            >
                              {{ time(r.start) }}
                            </v-col>
                            <v-col cols="3" class="py-0 my-1">{{
                              day(r.start)
                            }}</v-col>
                          </v-row>
                          <v-row v-if="reminders.length == 0">
                            <v-col style="font-size: 14px"
                              >No hay alarmas</v-col
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card raised elevation="6" class="my-1" id="notes">
                    <v-card-title
                      class="py-2"
                      style="
                        cursor: pointer;
                        text-decoration: underline;
                        display: flex;
                        justify-content: center;
                      "
                    >
                      {{ $tc("notes", 2) }}
                    </v-card-title>
                    <v-card-text style="background-color: #1e1e1e" class="pa-0">
                      <v-textarea
                        rows="5"
                        outlined
                        hide-details
                        class="complete_studio"
                        v-model="nota"
                        @blur="saveNota"
                      >
                      </v-textarea>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else cols="12" class="py-0">
          <v-row>
            <v-col cols="12" class="summary-text">
              <v-card raised elevation="6" class="my-1">
                <v-card-title
                  style="
                    cursor: pointer;
                    text-decoration: underline;
                    display: flex;
                    justify-content: center;
                  "
                  class="pa-2"
                >
                  {{ $tc("appointment", 2) }}
                </v-card-title>
                <v-card-text class="py-0 card_background">
                  <v-row>
                    <v-col cols="4">
                      <p class="" style="font-size: 20px">
                        {{ numAppointments }}
                      </p>
                      <span>
                        {{ $t("expenses.types.total") }}
                      </span>
                    </v-col>
                    <v-col cols="4" class="borders">
                      <p class="primary--text" style="font-size: 20px">
                        {{ check_out }}
                      </p>
                      <span>{{ $t("appointments.states.check_out") }}</span>
                    </v-col>
                    <v-col cols="4" class="borders">
                      <p class="primary--text" style="font-size: 20px">
                        {{ $n(income.tattooer, "currency_no_icon") }}€
                      </p>
                      <span>
                        {{ $t("total") }}
                      </span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="my-0">
            <v-col cols="12" class="summary-text">
              <v-card raised elevation="6" class="my-1">
                <v-card-title
                  style="
                    cursor: pointer;
                    text-decoration: underline;
                    display: flex;
                    justify-content: center;
                  "
                  class="pa-2"
                >
                  {{ $t("ingresos") }}
                </v-card-title>
                <v-card-text class="py-0 card_background">
                  <v-row>
                    <v-col cols="4">
                      <p style="font-size: 20px">
                        {{ $n(income.tattooer, "currency_no_icon") }}€
                      </p>
                      <span>
                        {{ $t("artist") }}
                      </span>
                    </v-col>
                    <v-col cols="4" class="borders">
                      <p style="font-size: 20px">
                        {{ $n(income.studio, "currency_no_icon") }}€
                      </p>
                      <span>{{ $tc("studio") }}</span>
                    </v-col>
                    <v-col cols="4" class="borders">
                      <p class="primary--text" style="font-size: 20px">
                        {{ $n(income.tattooer, "currency_no_icon") }}€
                      </p>
                      <span>
                        {{ $t("total") }}
                      </span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="12" class="summary-text">
              <v-card raised elevation="6" class="my-1">
                <v-card-title
                  style="
                    cursor: pointer;
                    text-decoration: underline;
                    display: flex;
                    justify-content: center;
                  "
                  class="pa-2"
                >
                  {{ $t("dashboard.debts") }}
                </v-card-title>
                <v-card-text class="py-0 card_background">
                  <v-row>
                    <v-col cols="6">
                      <div>
                        <p class="red--text" style="font-size: 20px">
                          {{ $n(pending.tattooer, "currency_no_icon") }}€
                        </p>
                        <span>
                          {{ $t("artist") }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <p class="green--text" style="font-size: 20px">
                          {{ $n(pending.customer, "currency_no_icon") }}€
                        </p>
                        <span>
                          {{ $tc("customer") }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-card
                raised
                elevation="6"
                class="my-1 card_background"
                style="height: 100%"
              >
                <v-card-title
                  style="
                    cursor: pointer;
                    text-decoration: underline;
                    display: flex;
                    justify-content: center;
                  "
                  class="pa-2 gris3"
                  align="center"
                >
                  Solicitudes Pendientes
                </v-card-title>
                <v-card-text class="py-0">
                  <v-row>
                    <v-col
                      align="center"
                      style="font-size: 40px; line-height: 1"
                    >
                      +{{ sol }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card
                raised
                elevation="6"
                class="my-1 card_background"
                style="height: 100%"
              >
                <v-card-title
                  style="
                    cursor: pointer;
                    text-decoration: underline;
                    display: flex;
                    justify-content: center;
                  "
                  class="pa-2 gris3"
                  align="center"
                >
                  Pagas y señales pendientes
                </v-card-title>
                <v-card-text class="py-0">
                  <v-row align="center">
                    <v-col
                      class="text-center"
                      style="font-size: 40px; line-height: 1"
                    >
                      +{{ pending_payed }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-row v-if="$vuetify.breakpoint.smAndDown" class="px-7">
      <v-col cols="4">
        <v-dialog fullscreen v-model="nota_dialog">
          <template v-slot:activator="{ on }">
            <v-card class="text-center my-1" v-on="on">
              <v-list>
                <v-icon size="80">$notes</v-icon>
                <p class="primary--text mb-0 text-caption">Notas</p>
              </v-list>
            </v-card>
          </template>
          <v-card>
            <v-card-title>NOTAS</v-card-title>

            <div class="close">
              <v-btn icon @click="nota_dialog = false" small>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-card-text>
              <v-textarea
                rows="19"
                outlined
                hide-details
                class="complete_studio"
                v-model="nota"
                style="border-radius: 10px"
              />
            </v-card-text>
            <v-card-actions class="pr-5">
              <v-spacer></v-spacer>

              <v-btn
                outlined
                @click="nota_dialog = false"
                style="height: 25px; width: 100px"
                elevation="0"
                >{{ $t("cancel") }}</v-btn
              >
              <v-btn
                @click="
                  saveNota();
                  nota_dialog = false;
                "
                style="
                  width: 100px !important;
                  color: #363533;
                  height: 25px;
                  padding-top: 10px !important;
                "
                elevation="0"
              >
                <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
                {{ $t("save", { name: $t("") }) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="4">
        <v-card
          class="text-center my-1"
          @click="$router.push({ name: 'tattooerList' })"
        >
          <v-list>
            <v-icon size="80">$artists</v-icon>
            <p class="primary--text mb-0 text-caption">{{ $t("artists") }}</p>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-dialog fullscreen v-model="alarm_dialog">
          <template v-slot:activator="{ on }">
            <v-card v-on="on" class="text-center my-1">
              <v-list>
                <v-icon size="80">$notification</v-icon>
                <p class="primary--text mb-0 text-caption">Alarmas</p>
              </v-list>
            </v-card>
          </template>
          <v-card>
            <v-card-title>Alarmas</v-card-title>

            <div class="close">
              <v-btn icon @click="alarm_dialog = false" small>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-card-text>
              <v-row
                v-for="r in reminders"
                :key="r.id"
                @click="
                  $router.push({ name: 'calendar', params: { event: r } })
                "
              >
                <v-col align="left" class="py-0 my-1 no-wrap">
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"> {{ r.name }}</span>
                    </template>
                    <p class="px-3 mb-0">{{ r.name }}</p>
                  </v-menu>
                </v-col>
                <v-col class="py-0 my-1 no-wrap">
                  {{ time(r.start) }}
                </v-col>
                <v-col cols="3" class="py-0 my-1">{{ day(r.start) }}</v-col>
                <v-col cols="12" class="py-0"><v-divider></v-divider></v-col>
              </v-row>
              <v-row v-if="reminders.length == 0">
                <v-col>No hay alarmas</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-row>
</template>
<style lang="sass" scoped>
.summary-text
  text-align: center

.summary-icon
  display: block
</style>
<script>
import "zingchart/es6";
import { mapActions, mapState } from "vuex";
import zingchartVue from "zingchart-vue";
export default {
  name: "TattooerDashboard",
  props: ["date", "tab", "title", "card", "cash"],
  data() {
    return {
      alarm_dialog: false,
      nota_dialog: false,
      numAppointments: 0,
      income: 0,
      tab: "total",
      pending_payed: 0,
      sol: 0,
      items: [],
      income: {},
      box_usage: {
        free: 0,
        occupied: 0,
      },
      totalCash: 0,
      tattooers: [],
      appointments: [],
      pending: {},
      pendingAppointments: [],
      pendingAppointmentsTotal: null,
      reminders: [],
      nota: null,
      check_out: 0,
      myData: {
        type: "ring",
        backgroundColor: "none",
        plotarea: {
          adjustLayout: true,
          height: 100,
        },
        plot: {
          width: 200,
          hight: 200,
          slice: 0.5,
          "value-box": {
            visible: false,
          },

          tooltip: {
            visible: false,
          },
          //border: 0,
          animation: {
            effect: 2,
            method: 1,
            sequence: 1,
            speed: 0.6,
          },
        },
        "scale-r": {
          "ref-angle": 270, //relative to the starting 90 degree position.
        },
      },
    };
  },
  computed: {
    pendingSetting() {
      let a = this.$store.getters["auth/getSetting"]("pending_setting");
      if (typeof a == "string") a = JSON.parse(a);
      return a;
    },
    ...mapState("reports", ["filters"]),
    to() {
      if (this.title == this.$t("expenses.final_balance")) {
        let fecha = new Date();
        return fecha.toISOString().slice(0, 10);
      } else {
        let date = new Date();
        date.setDate(date.getDate() - 1);
        return date.toISOString().slice(0, 10);
      }
    },
  },
  watch: {
    filters: {
      handler() {
        this.fetchStudioSummary();
      },
      deep: true,
    },
    filtersState: {
      handler() {
        this.fetchAmount();
      },
      deep: true,
    },
  },

  mounted() {
    this.fetchStudioSummary();
    this.loadNota();
  },

  methods: {
    ...mapActions("users", ["settingUpdate", "getSetting"]),
    loadNota() {
      this.getSetting({ key: "user_note" }).then((data) => {
        if (data.data !== null) {
          this.nota = data.data.value;
        }
      });
    },
    saveNota() {
      this.settingUpdate({
        key: "user_note",
        value: this.nota,
      }).then((data) => {
        this.loadNota();
      });
    },
    time(day) {
      console.log(day);
      let d = day.substring(11, 16);
      if (d === "00:00") return "Todo el dia";
      return d;
    },
    day(day) {
      let today = new Date();
      let date = new Date(day.substring(0, 10));
      if (today === date) {
        return "Hoy";
      } else {
        return this.$d(date, "dayMonth");
      }
    },
    checkBoxes(a) {
      let check = true;
      console.log(a.customer.user.first_name);
      if (!a.available_dates.length) return false;
      a.available_dates.forEach((d) => {
        console.log("d", d);
        if (!d.boxes_tattooer) check = false;
      });
      return check;
    },
    hours(min, max) {
      let mins = [":00"];
      let hs = [];
      let mn = +min; //.split(":")[0];
      let mx = +max; //.split(":")[0];

      for (let i = mn; i < mx; i++) {
        mins.forEach((m) => {
          if (i < 10) hs.push("0" + i + m);
          else hs.push(i + m);
        });
      }
      return hs;
    },
    checkAppointments(tattooer, hour) {
      let split = +hour.split(":")[0] + 1;
      let nextHour = 0;
      if (split < 10) nextHour = "0" + split + ":00";
      else nextHour = split + ":00";
      console.log("h", hour, nextHour, tattooer, this.appointments);
      let ap = this.appointments.find((ad) => {
        let start = ad.date.substring(11, 16);
        let end = ad.endDate.substring(11, 16);
        console.log(ad);
        return (
          ad.appointment.studio_id === tattooer.studio_id &&
          hour < end &&
          nextHour > start
        );
      });
      if (ap) return true;
      return false;
    },
    ...mapActions("reports", [
      "getTattooerSummary",
      "getStudioTattooersReport",
      "getPendingAppointmentsReport",
    ]),
    fetchStudioSummary() {
      this.getTattooerSummary({
        tattooerId: this.$store.state.auth.user.tattooer.id,
        filters: this.filters,
      }).then((data) => {
        this.numAppointments = data.appointments;
        this.income = data.income;
        this.pending_payed = data.pending_payed;
        this.box_usage.free = 100 - data.box_usage;
        this.box_usage.occupied = data.box_usage;
        this.sol = data.sol;
        this.check_out = data.check_out;
        this.income = data.income;
        this.items = [];
        this.totalCash = data.cash;
        this.pending = data.pending;
        if (data.box_usage > 0)
          this.items.push({
            values: [data.box_usage],
            "background-color": "var(--v-gris2-base)",
            border: "0",
          });
        this.items.push({
          values: [100 - data.box_usage],
          "background-color": "var(--v-primary-base)",
          border: "0",
        });
        this.reminders = data.reminders;
      });

      this.fetchStudioTattooers();
      this.fetchStudioTattooersReport();
      this.fetchPendingAppointments();
    },
    fetchStudioTattooersReport() {
      this.getStudioTattooersReport({
        studioId: this.$store.state.auth.user.tattooer.id,
        filters: this.filters,
      }).then((response) => {
        this.appointments = response;
      });
    },
    fetchPendingAppointments() {
      console.log("f", this.filters);
      this.getPendingAppointmentsReport({
        studioId: this.$store.state.auth.user.tattooer.id,
        filters: this.filters,
      }).then((result) => {
        console.log(result);
        this.pendingAppointments = result.data;
        this.pendingAppointmentsTotal = result.total - 3;
      });
    },
    ...mapActions("tattooers", ["getTattooerStudiosByDate"]),
    fetchStudioTattooers() {
      console.log("f", this.filters);
      this.getTattooerStudiosByDate({
        tattooer_id: this.$store.state.auth.user.tattooer.id,
        date: this.filters.from,
      }).then((data) => {
        this.tattooers = data;
      });
    },
  },

  components: {
    NotificationsList: () => import("@/components/notifications/List"),
    monthSelector: () => import("@/components/reports/MonthSelector"),
    ExpensesTable: () => import("@/components/expense/Table"),
    zingchart: zingchartVue,
  },
};
</script>

<style lang="sass" scoped>
.bottom_btn
  position: absolute
  bottom: 0
  left: 0
  right: 0

.card_min
  min-height: 160px

.pending_table
  max-height: 250px
  overflow-y: auto
  &::-webkit-scrollbar
    width: 5px
    height: 5px
    &::-webkit-scrollbar-thumb
      border-width: 1px
.summary-text
  text-align: center

h1
  font-size: 50px
  font-weight: 100

h2
  font-size: 20px
  color: var(--v-primary-base)
  font-weight: 100

h3
  font-size: 15px
  color: var(--v-primary-base)
  font-weight: 100
.v-icon
  color: var(--v-primary-base)

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th
  padding: 0px 0px

.card-text
  height: 79.5%
  padding-top: 7%

.card-text-small
  height: 79.5%
  padding-top: 2%

.div-text-small
  width: 50%
  margin-left: 28%

.borders
  border-left: 1px solid var(--v-primary-base)
.borders-double
  border-left: 1px solid var(--v-primary-base)
  border-right: 1px solid var(--v-primary-base)
@media (max-width: 960px)
  #economy,
  #appointments
    .v-card__text
      >.row
        >div
          //background-color: red
          padding-top: 0
          padding-bottom: 0
          height: 100px
          display: flex
          align-items: center
          justify-content: center
          position: relative
          h3:first-child
            //background-color: red !important
            position: absolute
            top: 5px
            width: 100%
          h1:nth-child(2),
          h2:nth-child(2)
            padding-top: 20px
  #economy
    .v-card__text
      >.row
        >div:nth-child(3)
          align-items: end
          >h1
            padding-bottom: 10px

  #mini-boxes
    height: 100px
    .v-card__text
      height: 67px
      .row
        height: 100%

  h1
    font-size: 40px
    .card-text-small
      height: 75.5%
      padding-top: 3%

@media (max-width: 1290px)
.form-label u
  font-size: 16px

#studio-dashbord
  h1
    line-height: 1
  .v-card__title
    text-transform: uppercase
  td,
  th
    padding: 0px 5px
    font-size: 0.75rem
    font-weight: 100
    &:first-child
      color: var(--v-primary-base)
      text-align: left !important
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
  td
    height: 25px
  th
    height: 20px
</style>
<style lang="sass">
#studio-dashbord
  .v-card__title
    padding-top: 0
  th
    color: var(--v-gris1-base) !important
  .v-data-table__wrapper
    width: fit-content
    min-width: 100%
  #economy,
  #appointments
    .v-card__text
      >.row
        >div
          >*:nth-child(2)
            p:first-child
              font-size: 10px
              p:nth-child(2)
                font-size: 20px
  @media (min-width: 960px)
    #economy
      .v-card__text
        >.row
          >div
            >h3:first-child
              height: 20px
            >*:nth-child(2)
              height: 50px
            >h2:nth-child(2)
              line-height: 50px
            >.v-btn:last-child
              //background-color: red !important
            >h2:last-child
              //background-color: red !important
              line-height: 1
            &.shorter
              max-width: 20%
              h3:first-child
                @media(max-width: 1290px)
                  word-spacing: 120px
    .economy-text
      >.row
        >div
          position: relative
          .v-btn
            position: absolute
            bottom: 0
            right: 0
            left: 0
    // .appointment-text
    //   .economy-text
    //   >.row
    //     height: 140px
    //     >div
    //       padding-top: 0
    //       padding-bottom: 0
    //       //background-color: red
    //       height: 100%
    //       position: relative
    //       .v-btn
    //         position: absolute
    //         bottom: 0
    //         right: 0
    //         left: 0

  #alarms
    .col
      font-size: 10px
      color: white
  .hours-table
    max-height: 160px
    overflow-y: auto
    &::-webkit-scrollbar
      width: 5px
      height: 5px
    &::-webkit-scrollbar-thumb
      border-width: 1px

  #zingchart-vue-0,
  #zingchart-vue-1,
  #zingchart-vue-2,
  #zingchart-vue-3,
  #zingchart-vue-4,
  #zingchart-vue-5
    width: 150% !important
  #zingchart-vue-0-license-text,
  #zingchart-vue-1-license-text,
  #zingchart-vue-2-license-text,
  #zingchart-vue-3-license-text,
  #zingchart-vue-4-license-text,
  #zingchart-vue-5-license-text
    display: none
  #notes
    .complete_studio
      border-radius: 0 0 10px 10px
      textarea
        height: 120px
        &::-webkit-scrollbar
          width: 5px
          height: 5px
        &::-webkit-scrollbar-thumb
          border-width: 1px
  #notifications
    .notification-title
      font-size: 10px
#boxesGraph
  height: 70px !important

.vertical
  display: flex
  align-items: center
  /*

  */
</style>
#tattooer-dashbord
  .v-card__title
    padding-top: 0
  th
    color: var(--v-gris1-base) !important
  .v-data-table__wrapper
    width: fit-content
    min-width: 100%
  #economy
    .v-card__text
      >.row
        >div
          >*:nth-child(2)
            p:first-child
              font-size: 10px
              p:nth-child(2)
                font-size: 20px
  @media (min-width: 960px)
    #economy
      .v-card__text
        >.row
          >div
            >h3:first-child
              height: 30px
            >*:nth-child(2)
              height: 50px
              p:first-child
                font-size: 10px
              p:nth-child(2)
                font-size: 20px
            >h2:nth-child(2)
              line-height: 50px
            >.v-btn:last-child
              //background-color: red !important
            >h2:last-child
              //background-color: red !important
              line-height: 1
            &.shorter
              max-width: 20%
              h3:first-child
                word-spacing: 120px
    .economy-text
      >.row
        >div
          position: relative
          .v-btn
            position: absolute
            bottom: 0
            right: 0
            left: 0
    .appointment-text
      .economy-text
      >.row
        height: 140px
        >div
          padding-top: 0
          padding-bottom: 0
          //background-color: red
          height: 100%
          position: relative
          .v-btn
            position: absolute
            bottom: 0
            right: 0
            left: 0

  #alarms
    .col
      font-size: 10px
  .hours-table
    height: 60px
    overflow-y: auto
    &::-webkit-scrollbar
      width: 5px
      height: 5px
    &::-webkit-scrollbar-thumb
      border-width: 1px

  #zingchart-vue-0,
  #zingchart-vue-1,
  #zingchart-vue-2,
  #zingchart-vue-3,
  #zingchart-vue-4,
  #zingchart-vue-5
    width: 150% !important
  #zingchart-vue-0-license-text,
  #zingchart-vue-1-license-text,
  #zingchart-vue-2-license-text,
  #zingchart-vue-3-license-text,
  #zingchart-vue-4-license-text,
  #zingchart-vue-5-license-text
    display: none
  #notes
    .complete
      border-radius: 0 0 10px 10px
      textarea
        height: 330px
        &::-webkit-scrollbar
          width: 5px
          height: 5px
        &::-webkit-scrollbar-thumb
          border-width: 1px
  #notifications
    .notification-title
      font-size: 10px
</style>